import React from 'react'
import HeadBut from '../Common/HeadBut/HeadBut.component'

const ContactComponent = () => {
   return (
      <>
         <HeadBut heading='Contact' />
      </>
   )
}

export default ContactComponent