import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
   width: 100%;
   height: 100%;
   background-color: #212a2f;
`

export const SubContainer = styled.div`
   width: 90%;
   height: 90%;
`

export const Ulist = styled.ul`
   padding: 20px;
`

export const UListItem = styled.li`
   list-style-type: none;
   padding: 15px 10px;
   background-color: ${(props) => props.active === "yes" ? "#fff" : "unset"};
`

export const UListLink = styled(Link)`
   text-decoration: none;
   color: white;
`

export const UListIcon = styled.div`
   color: ${(props) => props.active === "yes" ? "#212a2f" : "unset"};
   /* background-color: blue; */
   width: 20%;
`

export const UListName = styled.div`
   font-size: 22px;
   color: ${(props) => props.active === "yes" ? "#212a2f" : "unset"};
   font-weight: ${(props) => props.active === "yes" ? "bold" : "unset"};
`