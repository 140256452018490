import React, { useCallback, useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import Request from '../../ApiRequest/request'
import { useDispatch, useSelector } from 'react-redux'
import Actions from '../../Redux/actions/Actions'
import HeadBut from '../Common/HeadBut/HeadBut.component'
import Field from './Field.component'
import { Button } from "../Common/HeadBut/HeadBut.style"

const HomeComponent = () => {
   const positions = useSelector((state) => state.positions);
   const [data, setData] = useState(positions);
   const [newField, setNewField] = useState(false);
   const [havePositions, setHavePositions] = useState(true);
   const dispatch = useDispatch();

   const fetchData = useCallback(() => {
      if (data.length === 0) {
         Request.Get("/positions/", (response) => {
            if (response.positions.length !== 0) {
               setHavePositions(true);
               dispatch(Actions.fetchAllPositions(response.positions))
               setData(response.positions)
            }
            else {
               setHavePositions(false);
            }
         }, (err) => {
            toast("Initial Fetch Failed" + err, { autoClose: 1000 })
         })
      }
   }, [data, dispatch])

   useEffect(() => {
      if (positions.length === 0)
         fetchData();
      else if (data.length !== positions.length) {
         setData(positions);
         setNewField(false);
      }
   }, [fetchData, positions, data, setData])

   return (
      <>
         <ToastContainer />
         <HeadBut heading='Home' />
         <div className='py-5 px-2'>
            {
               havePositions ?
                  <>
                     {
                        data.length >= 3 ?
                           <>
                              {
                                 data.map((item, index) => {
                                    return <Field key={index} data={item.field} index={item._id} deleteAllowed={true} />
                                 })
                              }
                              {
                                 newField ?
                                    <Field data="" index={Number.MAX_SAFE_INTEGER} deleteAllowed={true} /> :
                                    null
                              }
                           </> :
                           data.length >= 2 ?
                              <>
                                 {
                                    data.map((item, index) => {
                                       return <Field key={index} data={item.field} index={item._id} deleteAllowed={false} />
                                    })
                                 }
                                 {
                                    newField ?
                                       <Field data="" index={Number.MAX_SAFE_INTEGER} deleteAllowed={false} /> :
                                       null
                                 }
                              </> :
                              data.length >= 1 ?
                                 <>
                                    {
                                       data.map((item, index) => {
                                          return <Field key={index} data={item.field} index={item._id} deleteAllowed={false} />
                                       })
                                    }
                                    <Field data="" index={Number.MAX_SAFE_INTEGER} deleteAllowed={false} />
                                 </> :
                                 null
                     }
                  </> :
                  <>
                     <Field data="" index={Number.MAX_SAFE_INTEGER} deleteAllowed={false} />
                     <Field data="" index={Number.MAX_SAFE_INTEGER} deleteAllowed={false} />
                  </>
            }
            {
               data.length < 5 ?
                  <div className='w-max' onClick={() => setNewField(true)}>
                     <Button>Add New</Button>
                  </div> :
                  null
            }
         </div>
      </>
   )
}

export default HomeComponent