import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import HeadBut from '../Common/HeadBut/HeadBut.component'
import { useSelector } from 'react-redux'
import { CustomFileField, FileField, FileName, FileUploadButton, FormContainer, FormField, FormInput, FormInputLabel, FormRow, FormTextArea, IconContainer, Preview } from './EditProject.style'
import { UploadClient } from '@uploadcare/upload-client'
import { toast, ToastContainer } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faToggleOff, faToggleOn } from '@fortawesome/free-solid-svg-icons'
import Request from '../../ApiRequest/request'
import { useDispatch } from 'react-redux/'
import Actions from '../../Redux/actions/Actions'
import axios from 'axios'

const EditProjectComponent = () => {
   const location = useLocation();
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const data = useSelector((state) => state.projects);
   const [name, setName] = useState("");
   const [url, setUrl] = useState("");
   const [desc, setDesc] = useState("");
   const [image, setImage] = useState("");
   const [imageName, setImageName] = useState("Upload File");
   const [position, setPosition] = useState(data.length + 1);
   const [visibility, setVisibility] = useState(true);
   const client = new UploadClient({ publicKey: 'e7428b291de86c7e5069' })
   const id = location.pathname.split("edit/")[1];
   let headbut = {
      heading: id === "new" ? "Add Project" : "Edit Project"
   }

   function sortData(a, b) {
      if (a["position"] === b["position"])
         return 0;
      else {
         return a["position"] < b["position"] ? -1 : 1
      }
   }

   function extractor(url_id) {
      if (url_id.search("google.com") !== -1) {
         var id = url_id.split("=");
         var url_link = "https://drive.google.com/uc?export=download&id=";
         var url = url_link.concat(id[1]);
      } else {
         url = url_id;
      }
      return url;
   }

   const handleSave = (e) => {
      e.preventDefault();
      let data = {
         name,
         url,
         desc,
         image,
         imageName,
         position,
         visibility
      }
      if (id === "new") {
         Request.Post("/projects/new", data, (res) => {
            if (res.message === "Success") {
               Request.Get("/projects/", (res) => {
                  dispatch(Actions.fetchAllProjects(res.projects.sort(sortData)));
                  navigate("/dashboard/projects");
               })
            }
         }, (err) => {
            console.log(err);
         })
      } else {
         Request.Patch("/projects/edit/" + id, data, (res) => {
            if (res.message === "Success") {
               Request.Get("/projects/", (res) => {
                  dispatch(Actions.fetchAllProjects(res.projects.sort(sortData)));
                  navigate("/dashboard/projects");
               })
            }
         }, (err) => {
            console.log(err);
         })
      }

   }
   //xbZW7wYIey6o3YUz portfolio_USER
   const uploadImage = (e) => {
      console.log(e.target.files, image);
      if (id !== "new") {
         let del = axios.delete("https://api.uploadcare.com/files/" + image.split(".com/")[1], {
            headers: {
               "Authorization": "Uploadcare.Simple e7428b291de86c7e5069:976e64c68ed2eaa1fcdf",
               "Content-Type": "application/json",
               "Accept": "application/vnd.uploadcare-v0.5+json"
            }
         });
         del.then((res) => {
            console.log(res)
            let response = client.uploadFile(e.target.files[0])
            response.then((res) => {
               toast("File Uploaded", { autoClose: 1000 })
               setImage(res.cdnUrl);
               setImageName(res.originalFilename);
            }).catch((err) => console.log(err))
         }).catch(err => console.log(err));
      }
      else {
         let response = client.uploadFile(e.target.files[0])
         response.then((res) => {
            toast("File Uploaded", { autoClose: 1000 })
            setImage(res.cdnUrl);
            setImageName(res.originalFilename);
         }).catch((err) => console.log(err))
      }
   }

   useEffect(() => {
      if (id === "new" && data.length === 0) {
         Request.Get("/projects/", (res) => {
            if (res.projects.length !== 0) {
               dispatch(Actions.fetchAllProjects(res.projects));
               setPosition(res.projects.length + 1)
            }
         }, () => {
            console.log("Fetch Failed")
         })
      } else if (id !== "new") {
         Request.Get("/projects/" + id, (res) => {
            setName(res.name);
            setDesc(res.desc);
            setImage(res.image);
            setImageName(res.imageName === undefined ? "No Name" : res.imageName);
            setPosition(res.position);
            setVisibility(res.visibility);
            setUrl(res.url);
         }, (err) => {
            console.log(err);
         })
      }
   }, [id, data, dispatch])

   return (
      <>
         <ToastContainer />
         <HeadBut heading={headbut.heading} buttonText="Save" link="/dashboard/projects" handleClick={handleSave} />
         <FormContainer>
            <FormRow>
               <FormField>
                  <FormInputLabel>Project Name</FormInputLabel>
                  <FormInput type="text" placeholder='Enter Project Name' value={name} onChange={(e) => setName(e.target.value)} name="name" id="name" />
               </FormField>
            </FormRow>
            <FormRow>
               <FormField>
                  <FormInputLabel>Description</FormInputLabel>
                  <FormTextArea placeholder='Enter Description' value={desc} name="desc" id="desc" onChange={(e) => setDesc(e.target.value)} />
               </FormField>
            </FormRow>
            <FormRow>
               <FormField>
                  <FormInputLabel>Project Link</FormInputLabel>
                  <FormInput type="url" placeholder='Enter Project Link' value={url} onChange={(e) => setUrl(e.target.value)} name="url" id="url" />
               </FormField>
               <FormField>
                  <FormInputLabel>Ranking</FormInputLabel>
                  <FormInput type="number" placeholder='Position' value={position} onChange={(e) => setPosition(e.target.value)} />
               </FormField>
               <FormField>
                  <FormInputLabel>Visibility</FormInputLabel>
                  <IconContainer>
                     <FontAwesomeIcon icon={visibility ? faToggleOn : faToggleOff} size="2x" onClick={() => setVisibility(!visibility)} />

                  </IconContainer>
               </FormField>
            </FormRow>
            <FormRow id="specialRow">
               <FormField>
                  <FormInputLabel>Project Image</FormInputLabel>
                  <FileField>
                     <FormInput type="file" accept='image/*' onChange={(e) => uploadImage(e)} />
                     <CustomFileField>
                        <FileName>{imageName}</FileName>
                        <FileUploadButton>Upload</FileUploadButton>
                     </CustomFileField>
                  </FileField>
               </FormField>
               <FormField>
                  <FormInputLabel>Preview</FormInputLabel>
                  <Preview image={extractor(image)} />
               </FormField>
            </FormRow>
         </FormContainer>
      </>
   )
}

export default EditProjectComponent