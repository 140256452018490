import React, { useEffect, useMemo } from 'react'
import "../../../CSS/common.css"
import { Cookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";
import * as Common from "../CommonStyle/Common.style"
import DashNav from '../../../Components/Common/DashNav/DashNav.component';
import DashboardComponent from '../../../Components/Dashboard/Dashboard.component';

const DashboardPage = () => {
   const cookie = useMemo(() => new Cookies(), []);
   const navigate = useNavigate();

   useEffect(() => {
      if (cookie.get("accessToken") === undefined) {
         navigate("/");
      }
   }, [cookie, navigate]);

   return (
      <Common.Container className='dflex'>
         <Common.NavContainer>
            <DashNav active="Dashboard" />
         </Common.NavContainer>
         <Common.ContentContainer>
            <DashboardComponent />
         </Common.ContentContainer>
      </Common.Container>
   )
}

export default DashboardPage