import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { faToggleOff, faToggleOn, faEye, faEdit, faTrashCan } from "@fortawesome/free-solid-svg-icons"
import { TableCell, TableRow, MenuContainer, MenuIcon } from "../Project/Project.style"
import Request from '../../ApiRequest/request'
import { useDispatch } from "react-redux"
import Actions from '../../Redux/actions/Actions'
import axios from 'axios'

const TestimonialRow = ({ item, index, setCard }) => {

   const dispatch = useDispatch();

   const handleClick = () => {
      Request.Patch("/testimonials/edit/visibility/" + item._id, {
         visibility: !item.visibility
      }, () => {
         Request.Get("/testimonials/", (response) => {
            dispatch(Actions.fetchAllTestimonials(response.testimonials.sort(sortData)))
         })
      })
   }

   function sortData(a, b) {
      if (a["position"] === b["position"])
         return 0;
      else {
         return a["position"] < b["position"] ? -1 : 1
      }
   }

   const handleDelete = () => {
      let del = axios.delete("https://api.uploadcare.com/files/" + item.image.split(".com/")[1], {
         headers: {
            "Authorization": "Uploadcare.Simple e7428b291de86c7e5069:976e64c68ed2eaa1fcdf",
            "Content-Type": "application/json",
            "Accept": "application/vnd.uploadcare-v0.5+json"
         }
      });
      del.then(() => {
         Request.Delete("/testimonials/delete/" + item._id, () => {
            Request.Get("/testimonials/", (response) => {
               dispatch(Actions.fetchAllTestimonials(response.testimonials.sort(sortData)))
            })
         })
      })
   }

   return (
      <>
         <TableRow key={index}>
            <TableCell width="8%">{index + 1}</TableCell>
            <TableCell width="20%">{item.authorName}</TableCell>
            <TableCell width="47%">{item.message.length > 60 ? item.message.slice(0, 60) + "...." : item.message}</TableCell>
            <TableCell width="10%">{item.visibility ? <FontAwesomeIcon icon={faToggleOn} size="xl" onClick={handleClick} /> : <FontAwesomeIcon icon={faToggleOff} size="xl" onClick={handleClick} />}</TableCell>
            <TableCell width="15%">
               <MenuContainer>
                  <MenuIcon onClick={() => setCard(index)}>
                     <FontAwesomeIcon icon={faEye} />
                  </MenuIcon>
                  <Link to={"/dashboard/testimonials/edit/" + item._id}>
                     <MenuIcon>
                        <FontAwesomeIcon icon={faEdit} />
                     </MenuIcon>
                  </Link>
                  <MenuIcon onClick={handleDelete}>
                     <FontAwesomeIcon icon={faTrashCan} />
                  </MenuIcon>
               </MenuContainer>
            </TableCell>
         </TableRow>
      </>

   )
}

export default TestimonialRow