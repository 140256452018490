import React from 'react'
import Card from './Card/Card.component'
import { Container } from "./Dashboard.style"
import "../../CSS/common.css"
import { faDiagramProject, faComment, faQuestion, faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const DashboardComponent = () => {
   function randomColor() {
      let red = Math.random() * 250;
      let blue = Math.random() * 250;
      let green = Math.random() * 250;
      return { red, blue, green }
   }

   let cardList = [
      {
         icon: <FontAwesomeIcon icon={faHome} size="3x" />,
         name: "Home",
         color: randomColor(),
         link: "/dashboard/home"
      },
      {
         icon: <FontAwesomeIcon icon={faDiagramProject} size="3x" />,
         name: "Project",
         color: randomColor(),
         link: "/dashboard/projects"
      },
      {
         icon: <FontAwesomeIcon icon={faComment} size="3x" />,
         name: "Testimonials",
         color: randomColor(),
         link: "/dashboard/testimonials"
      },
      {
         icon: <FontAwesomeIcon icon={faQuestion} size="3x" />,
         name: "Query",
         color: randomColor(),
         link: "/dashboard/query"
      }
   ]

   return (
      <Container className="dflex jcse aifs">
         {
            cardList.map((item, index) => {
               return <Card key={index} name={item.name} color={item.color} link={item.link} icon={item.icon} />
            })
         }
      </Container>
   )
}

export default DashboardComponent