import styled from "styled-components";

import { Link } from 'react-router-dom';

export const Container = styled.div`
   width: 300px;
   height: 200px;
   background-color: ${props => props.bgColor};
   margin: 30px 10px;
   &:hover{
      box-shadow: 0 0 20px 5px var(--overlay);
   }
   border-radius: 10px;
`

export const CardLink = styled(Link)`
`

export const IconContainer = styled.div`
   height: 80%;
   width: 100%;
   color: black;
`

export const IconSubContainer = styled.div`
   padding: 15px;
   height: 100px;
   width: 100px;
   border-radius: 50%;
   background-color: white;
   color: ${props => props.bgColor};
`

export const NameContainer = styled.div`
   height: 30%;
   width: 100%;
   font-size: 30px;
   color: white;
`