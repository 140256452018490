import React from 'react'
import { CardLink, Container, IconContainer, IconSubContainer, NameContainer } from './Card.style'

const Card = ({ color, name, link, icon }) => {

   let rgb = "rgb(" + color.red + "," + color.green + "," + color.blue + ");"

   return (
      <CardLink to={link}>
         <Container className='dflex fdn-col jcc aic' bgColor={rgb}>
            <IconContainer className='dflex jcc aic'>
               <IconSubContainer className='dflex jcc aic' bgColor={rgb}>
                  {icon}
               </IconSubContainer>
            </IconContainer>

            <NameContainer className='dflex jcc'>{name}</NameContainer>
         </Container>
      </CardLink>
   )
}

export default Card