import React, { useState, useEffect, useCallback } from 'react'
import HeadBut from '../Common/HeadBut/HeadBut.component'
import { SubContainer, CardContainer, TableContainer, TableHead, TableRow } from "./Project.style"
// import data from "./Projects.json"
import ProjectRow from './ProjectRow.component'
import ProjectCard from './Card/Card.component'
import { toast, ToastContainer } from 'react-toastify'
import Request from '../../ApiRequest/request'
import { useDispatch, useSelector } from "react-redux"
import Actions from '../../Redux/actions/Actions'

const ProjectComponent = () => {
   const [currentCard, setCurrentCard] = useState(0);
   const [haveProjects, setHaveProjects] = useState(true);
   const data = useSelector((state) => state.projects)
   const dispatch = useDispatch();

   function sortData(a, b) {
      if (a["position"] === b["position"])
         return 0;
      else {
         return a["position"] < b["position"] ? -1 : 1
      }
   }

   const fetchData = useCallback(() => {
      if (data.length === 0) {
         Request.Get("/projects/", (response) => {
            if (response.projects.length !== 0) {
               response.projects.sort(sortData);
               setHaveProjects(true);
               dispatch(Actions.fetchAllProjects(response.projects))
            }
            else {
               setHaveProjects(false)
            }
         }, (err) => {
            toast("Initial Fetch Failed" + err, { autoClose: 2000 });
         })
      }
   }, [data, dispatch])

   useEffect(() => {
      fetchData();
   }, [fetchData])

   const handleArrange = (pos, id) => {
      var newpos = parseInt(prompt("Enter new position"));
      if (pos === newpos) {
         toast("Entered Position is same as the current position", { autoClose: 3000 })
      }
      else {
         const dataIndex = data.findIndex((e) => e.position === newpos);
         console.log(dataIndex);
         let swapId = data[dataIndex]._id;
         console.log(swapId, id);
         Request.Patch("/projects/edit/position/" + id + "/" + swapId, null, () => {
            Request.Get("/projects/", (response) => {
               dispatch(Actions.fetchArrangeProjects(response.projects))
            }, (err) => {
               toast("Handle Arrange Fetch Failed" + err, { autoClose: 2000 });
            })
         })
      }
   }

   return (
      <>
         <ToastContainer />
         <HeadBut heading='Projects' buttonText='Add Project' link="/dashboard/projects/edit/new" />
         <SubContainer>
            <CardContainer>
               <ProjectCard data={data[currentCard]} />
            </CardContainer>
            <TableContainer>
               {
                  haveProjects ?
                     <>
                        <TableRow>
                           <TableHead width="8%">S. No.</TableHead>
                           <TableHead width="20%">Name</TableHead>
                           <TableHead width="32%">Description</TableHead>
                           <TableHead width="15%">Link</TableHead>
                           <TableHead width="10%">Visibility</TableHead>
                           <TableHead width="15%">Menu</TableHead>
                        </TableRow>
                        {
                           data.map((item, index) => {
                              let vis = item.visibility ? true : false;
                              return <ProjectRow handleArrange={handleArrange} vis={vis} index={index} item={item} key={index} setCard={setCurrentCard} />
                           })
                        }
                     </> :
                     <>
                        NO PROJECTS
                     </>
               }
            </TableContainer>
         </SubContainer>
      </>
   )
}

export default ProjectComponent