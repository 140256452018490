const ActionTypes = {
   FETCHED_ALL_PROJECT: "FETCHED_ALL_PROJECT",
   ADDED_NEW_PROJECT: "ADDED_NEW_PROJECT",
   MODIFIED_PROJECT: "MODIFIED_PROJECT",
   DELETED_PROJECT: "DELETED_PROJECT",
   REARRANGED_PROJECT: "REARRANGED_PROJECT",
   FETCHED_ALL_TESTIMONIAL: "FETCHED_ALL_TESTIMONIAL",
   ADDED_NEW_TESTIMONIAL: "ADDED_NEW_TESTIMONIAL",
   MODIFIED_TESTIMONIAL: "MODIFIED_TESTIMONIAL",
   DELETED_TESTIMONIAL: "DELETED_TESTIMONIAL",
   FETCHED_AFTER_REARRANGED: "FETCHED_AFTER_REARRANGED",
   FETCHED_AFTER_VISIBILITY: "FETCHED_AFTER_VISIBILITY",
   POSITION_ADDED: "POSITION_ADDED",
   POSITION_EDITED: "POSITION_EDITED",
   POSITION_DELETED: "POSITION_DELETED",
   FETCHED_ALL_POSITIONS: "FETCHED_ALL_POSITIONS",
   FETCHED_ALL_QUERIES: "FETCHED_ALL_QUERIES",
   DELETED_QUERY: "DELETED_QUERY"
}

export default ActionTypes