import styled from "styled-components";

export const Container = styled.div`
   width: 90%;
   height: 100%;
   padding: 25px 0;
`

export const Button = styled.div`
   background-color: var(--secCol);
   font-weight: bold;
   cursor: pointer;
   width: 60px;
   height: 30px;
   display: flex;
   justify-content: center;
   align-items: center;
`

export const SubContainer = styled.div`
   min-height: 85%;
   margin-top: 15px;
`

export const CardContainer = styled.div`
   width: "100%";
   height: ${props => props.height ? props.height : "300px"};
   display: flex;
   justify-content: center;
   margin-bottom: 1vh;
`

export const TableContainer = styled.div`
   width: 100%;
   height: ${props => props.height ? props.height : "385px"};
   overflow-y: auto;
   display: flex;
   flex-direction: column;
`

export const TableRow = styled.div`
   min-height: 50px;
   display: flex;
   margin-bottom: 10px;
`

export const TableHead = styled.div`
   width: ${props => props.width};
   margin-bottom:5px;
   font-size: 17px;
   font-weight: bold;
   display: flex;
   justify-content: center;
   
`

export const TableCell = styled.div`
   width: ${props => props.width};
   overflow: hidden;
   display: flex;
   justify-content: center;
`

export const MenuContainer = styled.div`
   width: 100%;
   height: 100%;
   display: flex;
   justify-content: center;
`

export const MenuIcon = styled.div`
   margin: 0 5px;
   cursor: pointer;
`