import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan, faEye } from "@fortawesome/free-solid-svg-icons"
import { TableCell, TableRow, MenuContainer, MenuIcon } from "../Project/Project.style"
import Request from '../../ApiRequest/request'
import { useDispatch } from "react-redux"
import Actions from '../../Redux/actions/Actions'

const QueryRow = ({ item, index, setCard }) => {

   const dispatch = useDispatch();

   const handleDelete = () => {
      Request.Delete("/query/" + item._id, () => {
         Request.Get("/query/", (response) => {
            dispatch(Actions.fetchAllQueries(response.query))
         })
      })
   }

   return (
      <>
         <TableRow key={index}>
            <TableCell width="8%">{index + 1}</TableCell>
            <TableCell width="20%">{item.name.length > 20 ? item.name.slice(0, 20) : item.name}</TableCell>
            <TableCell width="20%" className='break-words' style={{ display: "unset" }}>{item.subject}</TableCell>
            <TableCell width="42%" className='break-words' style={{ display: "unset" }}>{item.message.length > 60 ? item.message.slice(0, 60) : item.message}</TableCell>
            <TableCell width="10%">
               <MenuContainer>
                  <MenuIcon onClick={() => setCard(index)}>
                     <FontAwesomeIcon icon={faEye} />
                  </MenuIcon>
                  <MenuIcon onClick={handleDelete}>
                     <FontAwesomeIcon icon={faTrashCan} />
                  </MenuIcon>
               </MenuContainer>
            </TableCell>
         </TableRow>
      </>

   )
}

export default QueryRow