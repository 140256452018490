import React from 'react'
import { Button } from '../Project.style';
import * as tag from "./Card.style"
import "./Card.style.css"
import { FaQuoteLeft } from 'react-icons/fa'

const ProjectCard = ({ data, page = "Project" }) => {
   function extractor(url_id) {
      if (url_id.search("google.com") !== -1) {
         var id = url_id.split("=");
         var url_link = "https://drive.google.com/uc?export=download&id=";
         var url = url_link.concat(id[1]);
      } else {
         url = url_id;
      }
      return url;
   }
   return (
      <>
         {
            data !== undefined ?
               <>
                  {
                     page === "Project" ?
                        <tag.Container>
                           <tag.ImageContainer bgImage={extractor(data?.image)} />
                           <tag.DetContainer>
                              <tag.Heading>{data?.name}</tag.Heading>
                              <tag.Desc>{data?.desc?.length > 60 ? data?.desc.slice(0, 60) + "...." : data?.desc}</tag.Desc>
                              <a href={data?.url} target="_blank" rel="noreferrer">
                                 <tag.ButtonContainer>
                                    <Button>Go</Button>
                                 </tag.ButtonContainer>
                              </a>
                           </tag.DetContainer>
                        </tag.Container> :
                        page === "Testimonial" ?
                           <div className='flex justify-center w-full'>
                              <div className='container'>
                                 <div className="upperContainer">
                                    <div className="invertedCommaContainer">
                                       <FaQuoteLeft size={26} className='invertedComma' />
                                    </div>
                                    <div className="upperSubContainer">
                                       <tag.TestiImageContainer image={data.image} />
                                       <div className='namePostContainer'>
                                          <h2 className='name'>{data.authorName}</h2>
                                       </div>
                                    </div>
                                 </div>
                                 <div className='lowerContainer'>
                                    {data.message.length > 320 ? data.message.slice(0, 320) : data.message}
                                 </div>
                              </div>
                           </div> :
                           page === "Query" ?
                              <div className='flex w-full'>
                                 <div className='border-2 rounded flex justify-between flex-col p-3  w-full'>
                                    <div>
                                       <div>
                                          <span className='font-extrabold text-lg'>Subject : </span>
                                          <span>{data.subject}</span>
                                       </div>
                                       <div className='w-full'>
                                          <span className='font-extrabold text-lg'>Message : </span>
                                          <span className='break-words'>{data.message}</span>
                                       </div>
                                    </div>
                                    <div>
                                       {data.name}
                                    </div>
                                 </div>
                              </div> :
                              null
                  }
               </> : null
         }
      </>
   )
}

export default ProjectCard