import React from 'react'
import { useState } from 'react'
import { faEdit, faTrashCan } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch } from 'react-redux'
import Actions from '../../Redux/actions/Actions'
import Request from '../../ApiRequest/request'

const Field = ({ data, index, deleteAllowed }) => {
   const [text, setText] = useState(data)
   const [editable, setEditable] = useState(data.length === 0 ? true : false);
   const dispatch = useDispatch();

   function handleSave() {
      console.log(index);
      let dataField = {
         field: text
      };
      console.log(dataField);
      if (index !== Number.MAX_SAFE_INTEGER) {
         Request.Patch("/positions/" + index, dataField, (res) => {
            if (res.message === "Success") {
               Request.Get("/positions/", (res) => {
                  dispatch(Actions.editPosition(res.positions))
                  setEditable(false);
               }, (err) => {
                  console.log(err);
               })
            }
         }, (err) => {
            console.log(err);
         })
      }
      else {
         Request.Post("/positions", dataField, (res) => {
            if (res.message === "Success") {
               Request.Get("/positions/", (res) => {
                  dispatch(Actions.addNewPosition(res.positions))
                  setEditable(false);
               }, (err) => {
                  console.log(err);
               })
            }
         }, (err) => {
            console.log(err);
         })
      }
   }

   function handleDelete() {
      Request.Delete("/positions/" + index, (res) => {
         if (res.message === "Deleted Successfully!") {
            Request.Get("/positions", (res) => {
               dispatch(Actions.delPosition(res.positions))
            }, (err) => {
               console.log(err);
            })
         }
      }, (err) => {
         console.log(err);
      })
   }

   return (
      <>
         <div className='mb-5'>
            <input
               type='text'
               className={`border-2 pl-5 py-3 min-w-[250px] rounded-lg outline-none ${editable ? '' : 'cursor-not-allowed'}`}
               name={text + index}
               id={text + index}
               value={text}
               onChange={(e) => { setText(e.target.value) }}
               readOnly={editable ? false : true}
            />
            {
               editable ?
                  <span className='bg-blue-100 cursor-pointer px-5 py-3 ml-10' onClick={handleSave}>
                     Save
                  </span> :
                  <>
                     <FontAwesomeIcon className='ml-5 cursor-pointer' icon={faEdit} size="lg" onClick={() => setEditable(true)} />
                     {
                        deleteAllowed ?
                           <FontAwesomeIcon className='ml-5 cursor-pointer' icon={faTrashCan} size="lg" onClick={handleDelete} /> :
                           null
                     }
                  </>
            }
         </div>
      </>
   )
}

export default Field