import ActionTypes from "../constants/ActionTypes"

const fetchAllProjects = (projects) => {
   return {
      type: ActionTypes.FETCHED_ALL_PROJECT,
      payload: projects
   }
}

const fetchArrangeProjects = (projects) => {
   return {
      type: ActionTypes.FETCHED_AFTER_REARRANGED,
      payload: projects
   }
}

const fetchAllTestimonials = (testimonials) => {
   return {
      type: ActionTypes.FETCHED_ALL_TESTIMONIAL,
      payload: testimonials
   }
}

const fetchAllPositions = (positions) => {
   return {
      type: ActionTypes.FETCHED_ALL_POSITIONS,
      payload: positions
   }
}

const editPosition = (positions) => {
   return {
      type: ActionTypes.POSITION_EDITED,
      payload: positions
   }
}

const addNewPosition = (positions) => {
   return {
      type: ActionTypes.POSITION_EDITED,
      payload: positions
   }
}

const delPosition = (positions) => {
   return {
      type: ActionTypes.POSITION_EDITED,
      payload: positions
   }
}

const fetchAllQueries = (queries) => {
   return {
      type: ActionTypes.FETCHED_ALL_QUERIES,
      payload: queries
   }
}

const delQuery = (queries) => {
   return {
      type: ActionTypes.DELETED_QUERY,
      payload: queries
   }
}

const Actions = {
   fetchAllProjects,
   fetchAllTestimonials,
   fetchArrangeProjects,
   fetchAllPositions,
   editPosition,
   addNewPosition,
   delPosition,
   fetchAllQueries,
   delQuery
}

export default Actions