import React from 'react'
import PropTypes from 'prop-types'
import * as Tag from "./HeadBut.style"
import { Link } from "react-router-dom"

const HeadBut = props => {
   return (
      <Tag.Container>
         <Tag.HeadingContainer>
            <Tag.Heading>{props.heading}</Tag.Heading>
         </Tag.HeadingContainer>
         {
            props.buttonText ?
               <Link to={props.link} onClick={props.handleClick}>
                  <Tag.Button>{props.buttonText}</Tag.Button>
               </Link> :
               null
         }
      </Tag.Container>
   )
}

HeadBut.propTypes = {
   heading: PropTypes.string.isRequired,
   buttonText: PropTypes.string,
   link: PropTypes.string,
   handleClick: PropTypes.func
}

export default HeadBut