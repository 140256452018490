import { combineReducers } from "redux"
import * as Reducers from "./Reducer"

const reducers = combineReducers({
   positions: Reducers.positions,
   projects: Reducers.projects,
   testimonials: Reducers.testimonials,
   query: Reducers.queries
})

export default reducers