import axios from "axios"

const url = process.env.NODE_ENV === "production" ? "https://portfolio-kw.herokuapp.com/api/v1" : "http://localhost:5000/api/v1";

const Post = async (endpoint, body, onSuccess, onFail) => {
   try {
      const response = await axios.post(url + endpoint, { ...body });
      onSuccess(response.data);
   }
   catch (err) {
      onFail(err);
   }
}

const Get = async (endpoint, onSuccess, onFail) => {
   try {
      const response = await axios.get(url + endpoint);
      onSuccess(response.data);
   }
   catch (err) {
      onFail(err);
   }
}

const Patch = async (endpoint, body, onSuccess, onFail) => {
   try {
      const response = await axios.patch(url + endpoint, body);
      onSuccess(response.data);
   }
   catch (err) {
      onFail();
   }
}

const Delete = async (endpoint, onSuccess, onFail) => {
   try {
      const response = await axios.delete(url + endpoint);
      onSuccess(response.data);
   }
   catch (err) {
      onFail(err);
   }
}

const Request = {
   Post,
   Get,
   Patch,
   Delete
}

export default Request