import React, { useState, useEffect, useCallback } from 'react'
import HeadBut from '../Common/HeadBut/HeadBut.component'
import { SubContainer, CardContainer, TableContainer, TableHead, TableRow } from "../Project/Project.style"
// import ProjectRow from '../Project/ProjectRow.component'
import ProjectCard from '../Project/Card/Card.component'
import { toast, ToastContainer } from 'react-toastify'
import Request from '../../ApiRequest/request'
import { useDispatch, useSelector } from "react-redux"
import Actions from '../../Redux/actions/Actions'
import TestimonialRow from './TestimonialRow.component'

const TestimonialComponent = () => {
   const [currentCard, setCurrentCard] = useState(0);
   const [haveTestimonials, setHaveTestimonials] = useState(true);
   const data = useSelector((state) => state.testimonials)
   const dispatch = useDispatch();

   const fetchData = useCallback(() => {
      if (data?.length === 0) {
         Request.Get("/testimonials/", (response) => {
            if (response.testimonials.length !== 0) {
               setHaveTestimonials(true);
               dispatch(Actions.fetchAllTestimonials(response.testimonials))
            }
            else {
               setHaveTestimonials(false)
            }
         }, (err) => {
            toast("Initial Fetch Failed" + err, { autoClose: 2000 });
         })
      }
   }, [data, dispatch])

   useEffect(() => {
      fetchData();
   }, [fetchData])

   return (
      <>
         <ToastContainer />
         <HeadBut heading='Testimonials' buttonText='Add Testimonial' link="/dashboard/testimonials/edit/new" />
         <SubContainer>
            <CardContainer height="400px">
               <ProjectCard data={data[currentCard]} page="Testimonial" />
            </CardContainer>
            <TableContainer>
               {
                  haveTestimonials ?
                     <>
                        <TableRow>
                           <TableHead width="8%">S. No.</TableHead>
                           <TableHead width="20%">Name</TableHead>
                           <TableHead width="47%">Message</TableHead>
                           <TableHead width="10%">Visibility</TableHead>
                           <TableHead width="15%">Menu</TableHead>
                        </TableRow>
                        {
                           data?.map((item, index) => {
                              let vis = item.visibility ? true : false;
                              return <TestimonialRow vis={vis} index={index} item={item} key={index} setCard={setCurrentCard} />
                           })
                        }
                     </> :
                     <>
                        NO TESTIMONIALS
                     </>
               }
            </TableContainer>
         </SubContainer>
      </>
   )
}

export default TestimonialComponent