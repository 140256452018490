import styled from "styled-components";

export const Container = styled.div`
   height: 7vh;
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin: 0 10px;
`

export const HeadingContainer = styled.div`
   
`

export const Heading = styled.h1`
   font-size: 25px;
   font-weight: bold;
`

export const Button = styled.div`
   background-color: #fe5d70;
   padding: 10px 20px;
   font-weight: bold;
   font-size: 20px;
   cursor: pointer;
`