import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import * as DashNavComp from './DashNav.style'
import { faDiagramProject, faComment, faDashboard, faHome, faQuestion, faRightFromBracket } from '@fortawesome/free-solid-svg-icons'
// eslint-disable-next-line
import { useCookies } from 'react-cookie'
import { useNavigate } from 'react-router'
import { useEffect } from 'react'


const DashNav = ({ active }) => {
   // eslint-disable-next-line
   const [cookies, setCookie, removeCookie] = useCookies(["accessToken"]);
   const navigate = useNavigate();

   const navList = [
      {
         icon: <FontAwesomeIcon icon={faDashboard} size="xl" />,
         name: "Dashboard",
         link: "/dashboard"
      },
      {
         icon: <FontAwesomeIcon icon={faHome} size="xl" />,
         name: "Home",
         link: "/dashboard/home"
      },
      {
         icon: <FontAwesomeIcon icon={faDiagramProject} size="xl" />,
         name: "Project",
         link: "/dashboard/projects"
      },
      {
         icon: <FontAwesomeIcon icon={faComment} size="xl" />,
         name: "Testimonials",
         link: "/dashboard/testimonials"
      },
      {
         icon: <FontAwesomeIcon icon={faQuestion} size="xl" />,
         name: "Query",
         link: "/dashboard/query"
      },
      {
         icon: <FontAwesomeIcon icon={faRightFromBracket} size="xl" />,
         name: "Logout",
         link: "/"
      }
   ]

   const handleLogout = async (event) => {
      event.preventDefault();
      removeCookie('accessToken', { path: "/" })
   }

   useEffect(() => {
      if (cookies.accessToken === undefined)
         navigate("/");
   }, [cookies, navigate])

   return (
      <DashNavComp.Container className='dflex jcc aic'>
         <DashNavComp.SubContainer>
            <DashNavComp.Ulist>
               {
                  navList.map((item, index) => {
                     if (active === item.name) {
                        return <DashNavComp.UListItem active="yes" key={index}>
                           <DashNavComp.UListLink to={item.link} className='dflex aic'>
                              <DashNavComp.UListIcon active="yes">{item.icon}</DashNavComp.UListIcon>
                              <DashNavComp.UListName active="yes">{item.name}</DashNavComp.UListName>
                           </DashNavComp.UListLink>
                        </DashNavComp.UListItem>
                     } else if (item.name === "Logout") {
                        return <DashNavComp.UListItem key={index}>
                           <DashNavComp.UListLink to={item.link} onClick={handleLogout} className='dflex aic'>
                              <DashNavComp.UListIcon>{item.icon}</DashNavComp.UListIcon>
                              <DashNavComp.UListName>{item.name}</DashNavComp.UListName>
                           </DashNavComp.UListLink>
                        </DashNavComp.UListItem>
                     } else {
                        return <DashNavComp.UListItem key={index}>
                           <DashNavComp.UListLink to={item.link} className='dflex aic'>
                              <DashNavComp.UListIcon>{item.icon}</DashNavComp.UListIcon>
                              <DashNavComp.UListName>{item.name}</DashNavComp.UListName>
                           </DashNavComp.UListLink>
                        </DashNavComp.UListItem>
                     }
                  })
               }
            </DashNavComp.Ulist>
         </DashNavComp.SubContainer>
      </DashNavComp.Container>
   )
}

export default DashNav