import styled from "styled-components"

export const Container = styled.div`
`

export const NavContainer = styled.div`
   width: 25%;
   height: 100vh;
   position: fixed;
`

export const ContentContainer = styled.div`
   margin-left: 25%;
   width: 75%;
   min-height: 100vh;
   display: flex;
   justify-content: center;
   align-items: center;
`
