import React, { useEffect, useMemo, useState } from 'react'
import "../../CSS/common.css"
import { ToastContainer, toast } from 'react-toastify';
import Request from '../../ApiRequest/request';
import { Cookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";
import * as Login from "./Login.style"

const LoginComponent = () => {
   const [username, setUsername] = useState("");
   const [password, setPassword] = useState("");
   let navigate = useNavigate();
   const cookie = useMemo(() => new Cookies(), []);

   const handleSubmit = () => {
      if (username === "" || password === "") {
         toast("Username or password is empty", {
            autoClose: 3000
         });
      }
      else {
         let data = {
            username: username,
            password: password
         }
         Request.Post("/auth/login", data, function (response) {

            if (response.status === 200 && response.message === "Login Successful!") {
               let d = new Date();
               d.setTime(d.getTime() + 3600000);
               cookie.set("accessToken", response.accessToken, { path: "/", expires: d });
               navigate("/dashboard", { replace: true })
            }
         }, function () {
            toast("Login Failed!", {
               autoClose: 3000
            });
         })
      }
   }

   useEffect(() => {
      if (cookie.get("accessToken") !== undefined) {
         navigate("/dashboard", { replace: true })
      }
   }, [cookie, navigate])

   return (
      <Login.Container className='dflex jcc aic'>
         <ToastContainer />
         <Login.SubContainer className="dflex fdn-col jcc aic">
            <Login.HeadingContainer className="headingContainer dflex jcc aic">
               <Login.Heading className="heading">Login</Login.Heading>
            </Login.HeadingContainer>
            <Login.FormContainer className='dflex aic jcse fdn-col'>
               <Login.FieldContainer>
                  <Login.TextInput type="text" placeholder='Enter Username' autoComplete='off' name="adminName" id="adminName" value={username} onChange={(e) => setUsername(e.target.value)} />
               </Login.FieldContainer>
               <Login.FieldContainer>
                  <Login.TextInput type="password" placeholder='Enter Password' name="adminUserPassword" id="adminPassword" value={password} onChange={(e) => setPassword(e.target.value)} />
               </Login.FieldContainer>
               <Login.FieldContainer className='dflex jcc aic'>
                  <Login.Button type="submit" name="adminSubmit" id="adminSubmit" onClick={handleSubmit} />
               </Login.FieldContainer>
            </Login.FormContainer>
         </Login.SubContainer>
      </Login.Container>
   )
}

export default LoginComponent