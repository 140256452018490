import React, { useCallback, useEffect, useState } from 'react'
// eslint-disable-next-line
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import Request from '../../ApiRequest/request';
import Actions from '../../Redux/actions/Actions';
// import Actions from '../../Redux/actions/Actions';
import HeadBut from '../Common/HeadBut/HeadBut.component'
import ProjectCard from '../Project/Card/Card.component';
import { CardContainer, SubContainer, TableContainer, TableHead, TableRow } from '../Project/Project.style';
import QueryRow from './QueryRow.component';

const QueryComponent = () => {
   const [currentCard, setCurrentCard] = useState(0);
   const [haveQuery, setHaveQuery] = useState(false);
   const data = useSelector((state) => state.query);
   const dispatch = useDispatch();

   const fetchData = useCallback(() => {
      if (data?.length === 0) {
         Request.Get("/query", (response) => {
            if (response.query.length !== 0) {
               setHaveQuery(true);
               dispatch(Actions.fetchAllQueries(response.query))
            }
            else {
               setHaveQuery(false);
            }
         }, (err) => {
            toast("Initial Fetch Failed " + err, { autoClose: 2000 });
         })
      }
   }, [data, dispatch])

   useEffect(() => {
      fetchData();
   }, [fetchData])

   return (
      <>
         <ToastContainer />
         <HeadBut heading='Query' />
         <SubContainer>
            {
               haveQuery ?
                  <>
                     <CardContainer height="200px">
                        <ProjectCard data={data[currentCard]} page="Query" />
                     </CardContainer>
                     <TableRow>
                        <TableHead width="8%">S.No.</TableHead>
                        <TableHead width="20%">Name</TableHead>
                        <TableHead width="20%">Subject</TableHead>
                        <TableHead width="42%">Message</TableHead>
                        <TableHead width="10%">Menu</TableHead>
                     </TableRow>
                     <TableContainer height="450px">
                        {
                           data?.map((item, index) => {
                              return <QueryRow index={index} item={item} key={index} setCard={setCurrentCard} />
                           })
                        }
                     </TableContainer>
                  </> :
                  <>
                     NO QUERIES
                  </>
            }
         </SubContainer>
      </>
   )
}

export default QueryComponent