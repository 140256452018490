import styled from "styled-components"

export const Container = styled.div`
   width: 100%;
   height: 100vh;
   background-color: var(--overlay);
`

export const SubContainer = styled.div`
   width: 450px;
   height: 300px;
   background-color: var(--white);
   border-radius: 10px;
   box-shadow: 0 0 20px 10px var(--overlay);
`

export const HeadingContainer = styled.div`
   width: 100%;
   font-size: 25px;
   height: 20%;
`

export const Heading = styled.h3`
   
`

export const FormContainer = styled.div`
   width: 100%;
   height: 70%;
`

export const FieldContainer = styled.div`
   width: 70%;
   height: 40px;
`

export const TextInput = styled.input`
   width: 100%;
   height: 100%;
   background-color: var(--white);
   border: 1px solid black;
   padding-left: 10px;
   outline: none;
`

export const Button = styled.input`
   height: 100%;
   width: 40%;
   background-color: var(--secCol);
   color: var(--white);
   font-size: 1.2rem;
   cursor: pointer;
   border: none;
`