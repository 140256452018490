import styled from "styled-components";

export const FormContainer = styled.div`
   border: 1px solid;
   min-height: 80vh;
   width: 100%;
   padding: 30px;
   padding-bottom: 0;
   display: flex;
   flex-direction: column;
`

export const FormRow = styled.div`
   width: 100%;
   display: flex;
   margin-bottom: 20px;
`

export const FormField = styled.div`
   display: flex;
   width: 100%;
   flex-direction: column;

   ${FormRow}#specialRow > &:nth-child(1) {
   }
   
   ${FormRow}#specialRow > &:nth-child(2) {
      width: 80%;
      // margin-right: 0;
   }

   &:nth-child(2){
      width: 20%;
      margin-left: 20px;
   }
`

export const IconContainer = styled.div`
   display: flex;
   height: 40px;
   width: 100%;
   padding-left: 15px;
   border: 1px solid #858585;
   align-items: center;
`

export const Preview = styled.div`
   width: 100%;
   min-height: 25vh;
   background-position: center center;
   background-image: url(${props => props.image !== "" ? props.image : null});
   background-repeat: no-repeat;
   background-size: contain;
   border: 1px solid #858585;
`

export const FileField = styled.div`
   position: relative;
`

export const CustomFileField = styled.div`
   border: 1px solid #858585;
   width: 100%;
   height: 40px;
   display: flex;
   justify-content: space-evenly;
   align-items: center;
   cursor: pointer;
`

export const FileName = styled.div`
   width: 75%;
   color: #858585;
`

export const FileUploadButton = styled.div`
   width: 20%;
   height: 90%;
   background-color: var(--secCol);
   font-weight: bold;
   display: flex;
   justify-content: center;
   align-items: center;
`

export const FormInput = styled.input`
   width: 100%;
   height: 40px;
   padding-left: 15px;
   border: 1px solid #858585;
   
   &[type=file]{
      position: absolute;
      top: 0;
      opacity: 0;
      cursor: pointer;
   }
`

export const FormInputLabel = styled.label`
   margin-bottom: 5px;
   font-size: 20px;
   font-weight: bold;
`

export const FormTextArea = styled.textarea`
   height: 200px;
   padding-left: 15px;
   padding-top: 15px;
   resize: none;
   border: 1px solid #858585;
   outline: none;
`