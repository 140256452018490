import styled from "styled-components";

export const Container = styled.div`
   width: 300px;
   height: 100%;
   border: 2px solid var(--borderCol);
   background-color: var(--tempWhite);
   box-shadow: 0 0 10px var(--backGrey);
`

export const ImageContainer = styled.div`
   height: 55%;
   width: 100%;
   background-image: url(${props => props.bgImage});
   background-size: contain;
   background-position: top center;
   background-repeat: no-repeat;
`

export const TestiImageContainer = styled.div`
   margin-bottom: 10px;
   height: 100px;
   width: 100px;
   background-image: url(${props => props.image});
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   border-radius: 100%;
`

export const DetContainer = styled.div`
   height: 45%;
   padding: 0 10px;
   padding-top: 5px;
`

export const Heading = styled.h3`
   margin-bottom: 10px;
   text-align: center;
`

export const Desc = styled.p`
   margin-bottom: 10px;
`

export const ButtonContainer = styled.div`
   display: flex;
   justify-content: flex-end;
`