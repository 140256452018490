import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import AboutPage from './Pages/Dashboard/About/About.page'
import EditAboutPage from './Pages/Dashboard/About/EditAbout.page'
import HomePage from './Pages/Dashboard/Home/Home.page'
import ProjectPage from './Pages/Dashboard/Projects/Project.page'
import TestimonialPage from './Pages/Dashboard/Testimonials/Testimonial.page'
import EditProjectPage from './Pages/Dashboard/Projects/EditProject.page'
import EditTestimonialPage from './Pages/Dashboard/Testimonials/EditTestimonial.page'
import LoginPage from './Pages/Login/Login.page'
import ContactPage from './Pages/Dashboard/Contact/Contact.page'
import EditContactPage from './Pages/Dashboard/Contact/EditContact.page'
import QueryPage from './Pages/Dashboard/Query/Query.page'
import DashboardPage from './Pages/Dashboard/Dashboard/Dashboard.page'

const App = () => {
   return (
      <>
         <BrowserRouter>
            <Routes>
               <Route path="/" element={<LoginPage />} />
               <Route path="/dashboard" element={<DashboardPage />} />
               <Route path="/dashboard/home" element={<HomePage />} />
               <Route path="/dashboard/about" element={<AboutPage />} />
               <Route path="/dashboard/about/edit" element={<EditAboutPage />} />
               <Route path="/dashboard/projects" element={<ProjectPage />} />
               <Route path="/dashboard/projects/edit/:id" element={<EditProjectPage />} />
               <Route path="/dashboard/testimonials" element={<TestimonialPage />} />
               <Route path="/dashboard/testimonials/edit/:id" element={<EditTestimonialPage />} />
               <Route path="/dashboard/contact" element={<ContactPage />} />
               <Route path="/dashboard/contact/edit/:id" element={<EditContactPage />} />
               <Route path="/dashboard/query" element={<QueryPage />} />
            </Routes>
         </BrowserRouter>
      </>
   )
}

export default App