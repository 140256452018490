import ActionTypes from "../constants/ActionTypes";

// eslint-disable-next-line
const initialStateObject = {}
const initialStateArray = []

export const projects = (state = initialStateArray, { type, payload }) => {
   switch (type) {
      case ActionTypes.FETCHED_ALL_PROJECT:
         return payload;

      default:
         return state;
   }
}

export const testimonials = (state = initialStateArray, { type, payload }) => {
   switch (type) {
      case ActionTypes.FETCHED_ALL_TESTIMONIAL:
         return payload;

      default:
         return state;
   }
}

export const positions = (state = initialStateArray, { type, payload }) => {
   switch (type) {
      case ActionTypes.FETCHED_ALL_POSITIONS:
         return payload;
      case ActionTypes.POSITION_ADDED:
         return payload;
      case ActionTypes.POSITION_DELETED:
         return payload;
      case ActionTypes.POSITION_EDITED:
         return payload;
      default:
         return state;
   }
}

export const queries = (state = initialStateArray, { type, payload }) => {
   switch (type) {
      case ActionTypes.FETCHED_ALL_QUERIES:
         return payload;
      case ActionTypes.DELETED_QUERY:
         return payload;
      default:
         return state;
   }
}